<template>
  <div class="col-12 col-lg-6 col-xl-7">
    <div class="row">
      <div class="col-12 col-xl-6">
        <validation-provider
          tag="div"
          :name="$t('form.labels.businessType')"
          rules="required"
          mode="passive"
          v-slot="{ errors }"
        >
          <ui-select
            v-model="form.type"
            :items="types"
            :label="$t('form.labels.businessType')"
            :placeholder="$t('form.placeholders.select')"
            label-key="label"
            value-key="value"
            filterable
            required
            :error="errors[0]"
            :disabled="!editable"
          />
        </validation-provider>

        <template v-if="form.type">
          <validation-provider
            v-if="showMasterLocationSelect"
            tag="div"
            :name="$t('form.labels.locationRole')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-select
              v-model="form.isMasterLocation"
              :items="roleTypes"
              :label="$t('form.labels.locationRole')"
              :placeholder="$t('form.placeholders.select')"
              label-key="label"
              value-key="value"
              filterable
              required
              :error="errors[0]"
              :disabled="!editable"
            />
          </validation-provider>

          <template v-if="!isMasterLocation">
            <validation-provider
              tag="div"
              :name="$t('form.labels.tipCollectionType')"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.tipsType"
                :items="tipsTypes"
                :label="$t('form.labels.tipCollectionType')"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="value"
                filterable
                required
                :error="errors[0]"
                :disabled="!editable"
              />
            </validation-provider>

            <validation-provider
              v-if="showPaymentSystem"
              tag="div"
              :name="$t('form.labels.paymentSystem')"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.paymentSystem"
                :items="paymentSystems"
                :label="$t('form.labels.paymentSystem')"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="value"
                filterable
                required
                :error="errors[0]"
                :disabled="!isSupportAdminEditable"
              />
            </validation-provider>

            <validation-provider
              v-if="showTaxReportingType"
              tag="div"
              :name="$t('form.labels.taxReportingType')"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.taxReportingType"
                :items="taxReportingTypes"
                :label="$t('form.labels.taxReportingType')"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="value"
                filterable
                required
                :error="errors[0]"
                :disabled="!editable"
              />
            </validation-provider>

            <validation-provider
              v-if="showTaxesSelect"
              tag="div"
              :name="$t('form.labels.taxes')"
              mode="passive"
              :rules="taxesValidationRules"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.taxes"
                :items="taxTypes"
                :label="$t('form.labels.taxes')"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="value"
                filterable
                :error="errors[0]"
                :disabled="isTaxesDisabled"
                :required="isTaxesRequired"
              />
            </validation-provider>

            <validation-provider
              v-if="showTipPayoutType"
              tag="div"
              :name="$t('form.labels.tipPayoutType')"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.tipPayoutType"
                :items="tipPayoutTypeList"
                :label="$t('form.labels.tipPayoutType')"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="value"
                filterable
                required
                :error="errors[0]"
                :disabled="!editable"
              />
            </validation-provider>

            <validation-provider
              v-if="showCaseType"
              tag="div"
              :name="$t('form.labels.case')"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.caseType"
                :items="caseTypes"
                :label="$t('form.labels.case')"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="value"
                filterable
                required
                :error="errors[0]"
                :disabled="!isSupportAdminEditable"
              />
            </validation-provider>

            <validation-provider
              v-if="showPaymentPage"
              tag="div"
              :name="$t('form.labels.mainLandingPage')"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.paymentPage"
                :items="paymentPages"
                :label="$t('form.labels.mainLandingPage')"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="value"
                filterable
                required
                :error="errors[0]"
              />
            </validation-provider>

            <validation-provider
              v-if="showVenue"
              tag="div"
              :name="$t('form.labels.venueCharge')"
              :rules="venueDisabled ? {} : 'required|decimal|min_value:0|max_value:100'"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.workplaceFee"
                :label="$t('form.labels.venueCharge')"
                :required="!venueDisabled"
                :disabled="venueDisabled"
                :error="errors[0]"
              />
            </validation-provider>

            <template v-if="showTipout">
              <validation-provider
                tag="div"
                :name="$t('form.labels.tipOut')"
                :rules="{ required: !primaryStaffDisabled }"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.primaryStaff"
                  :items="staffTypes"
                  :label="$t('form.labels.tipOut')"
                  :placeholder="$t('form.placeholders.select')"
                  label-key="name"
                  value-key="value"
                  filterable
                  :required="!primaryStaffDisabled"
                  :disabled="primaryStaffDisabled"
                  :error="errors[0]"
                />
              </validation-provider>

              <validation-provider
                tag="div"
                :name="$t('form.labels.tipOutAmount')"
                :rules="primaryStaffDisabled ? {} : 'required|decimal|min_value:1|max_value:100'"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.primaryTipout"
                  :label="$t('form.labels.tipOutAmount')"
                  :required="!primaryStaffDisabled"
                  :disabled="primaryStaffDisabled"
                  :error="errors[0]"
                />
              </validation-provider>
            </template>

            <validation-provider
              v-if="showTimeSheet"
              tag="div"
              rules="required"
              :name="$t('form.labels.timeSheet')"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.timesheetType"
                :items="timeTypes"
                :label="$t('form.labels.timeSheet')"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="value"
                :error="errors[0]"
                required
              />
            </validation-provider>

            <ui-select
              v-if="isAdmin"
              v-model="form.abVariant"
              :items="abVariants"
              label="A/B Variants"
              :placeholder="$t('form.placeholders.select')"
              label-key="label"
              value-key="value"
            />

            <location-type-review
              :form="form"
            />

            <validation-provider
              v-if="showMultipleTipsSwitcher"
              tag="div"
              :name="$t('form.labels.multipleTips')"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.multiTipsMode"
                :items="MULTI_TIPS_MODES"
                :label="$t('form.labels.multipleTips')"
                :placeholder="$t('form.placeholders.select')"
                :error="errors[0]"
              />
            </validation-provider>

            <location-groups
              v-if="showGroups"
              :location="form"
              :groups="form.groups"
            />
          </template>
          <template v-else>
            <location-sublocations
              :location="form"
              :sublocations="form.sublocations"
            />
          </template>
        </template>
      </div>

      <location-dashboard-access
        :form="form"
        :data="data"
        :is-onboarding-loading="isOnboardingLoading"
        :disabled="!form.type"
        @completed="$emit('completed')"
        @activate="$emit('activate')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import abVariants from '@/config/abVariants';
import { SWITZERLAND_TYPES, DEFAULT_TYPES } from '@/config/businessTypes';
import { ALL_TAX_REPORTING_TYPES } from '@/config/taxReportingTypes';
import { MULTI_TIPS_MODES } from '@/config/multiTipsModes';
import LocationSublocations from '@/components/Location/LocationSublocations.vue';
import { PAYMENT_PAGE_TYPE } from '@/config/paymentPageType';
import { CASE_TYPE } from '@/config/caseType';
import LocationGroups from './LocationGroups.vue';
import LocationDashboardAccess from './LocationDashboardAccess.vue';
import LocationTypeReview from './LocationTypeReview.vue';

export default {

  components: {
    LocationSublocations,
    LocationGroups,
    LocationDashboardAccess,
    LocationTypeReview,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isOnboardingLoading: Boolean,
    editable: Boolean,
  },

  data() {
    return {
      abVariants,
      MULTI_TIPS_MODES,
    };
  },

  computed: {
    ...mapGetters('auth', [
      'isAdmin',
      'isAdminOrSales',
      'isSupportAdmin',
    ]),
    isTipPayoutEmployer() {
      return this.form.tipPayoutType === 'EMPLOYER';
    },
    isSwitzerland() {
      return this.form?.country === 'ch';
    },
    types() {
      return this.isSwitzerland ? SWITZERLAND_TYPES : DEFAULT_TYPES;
    },
    taxReportingTypes() {
      return ALL_TAX_REPORTING_TYPES.map((item) => ({
        ...item,
        disabled: item.value === 'SELF_REPORTING' && this.isUk && this.isCommon,
      }));
    },
    tipsTypes() {
      return [
        {
          value: 'PERSONAL',
          label: this.$t('tipsTypes.individual'),
        },
        {
          value: 'COMMON',
          label: this.$t('tipsTypes.commonSimple'),
          disabled: this.isSelfReporting,
        },
        {
          value: 'COMMON_PRIORITY',
          label: this.$t('tipsTypes.commonAdvanced'),
          disabled: this.isSpaOrSalon || this.isSelfReporting || this.showMasterLocationSelect || this.isUAE,
        },
      ];
    },
    roleTypes() {
      return [
        {
          value: true,
          label: this.$t('roleTypes.masterLocation'),
        },
        {
          value: false,
          label: this.$t('roleTypes.basicLocation'),
        },
      ];
    },
    taxTypes() {
      return [
        {
          value: 'GROSS',
          label: this.$t('taxTypes.gross'),
        },
        {
          value: 'NET',
          label: this.$t('taxTypes.net'),
        },
      ];
    },
    tipPayoutTypeList() {
      return [
        {
          value: 'INDIVIDUAL',
          label: 'Individual',
          visible: true,
        },
        {
          value: 'EMPLOYER',
          label: 'Employer',
          visible: true,
        },
      ].filter((item) => item.visible);
    },
    caseTypes() {
      return [
        {
          value: CASE_TYPE.CASE_1,
          label: 'Case 1 - Collection + Allocation + Personal distribution',
          disabled: this.isStripe,
        },
        {
          value: CASE_TYPE.CASE_2,
          label: 'Case 2 - Collection + Allocation + Company distribution',
          disabled: this.isStripe,
        },
        {
          value: CASE_TYPE.CASE_3,
          label: 'Case 3 - Collection + Company distribution',
          disabled: false,
        },
        {
          value: CASE_TYPE.CASE_4,
          label: 'Case 4 - Allocation + Personal distribution',
          disabled: this.isStripe,
        },
      ];
    },
    paymentSystems() {
      return [
        {
          value: 'STRIPE',
          label: 'Stripe',
          visible: this.isUAE,
          disabled: !this.isCommon,
        },
        {
          value: 'RYFT',
          label: 'Ryft',
          visible: this.isUk,
          disabled: false,
        },
        {
          value: 'CHECKOUT_UK_DASHBOARD',
          label: 'Checkout UK Dashboard',
          visible: true,
          disabled: this.isTipPayoutEmployer,
        },
        {
          value: 'CHECKOUT_UAE',
          label: 'Checkout UAE',
          visible: this.isUAE,
          disabled: this.isTipPayoutEmployer,
        },
        {
          value: 'CHECKOUT',
          label: 'Checkout UK',
          visible: true,
          disabled: true,
        },
        {
          value: 'PAYER_MAX',
          label: 'PayerMax',
          visible: true,
          disabled: false,
        },
      ].filter((item) => item.visible);
    },
    paymentPages() {
      return [
        {
          value: PAYMENT_PAGE_TYPE.DIRECT,
          label: this.$t('paymentPages.direct'),
          disabled: this.isSpaOrSalon || this.showMasterLocationSelect,
        },
        {
          value: PAYMENT_PAGE_TYPE.LIST,
          label: this.$t('paymentPages.list'),
        },
        {
          value: PAYMENT_PAGE_TYPE.GROUPS,
          label: this.$t('paymentPages.groups'),
        },
      ];
    },
    timeTypes() {
      return [
        {
          value: 'HOURS',
          label: 'Hours',
        },
        {
          value: 'UNITS',
          label: 'Units',
        },
      ];
    },
    staffTypes() {
      return this.$store.getters['handbook/staffTypes']
        .filter(({ types }) => types.includes(this.form.type))
        .filter(({ countries }) => countries.includes(this.form.country));
    },
    venueDisabled() {
      return !this.form.tipsType || this.form.tipsType === 'PERSONAL';
    },
    primaryStaffDisabled() {
      return this.form.tipsType !== 'COMMON_PRIORITY';
    },
    isSelfReporting() {
      return this.form.taxReportingType === 'SELF_REPORTING';
    },
    isCommon() {
      return this.form.tipsType === 'COMMON'
        || this.form.tipsType === 'COMMON_PRIORITY';
    },
    isRyft() {
      return this.form.paymentSystem === 'RYFT';
    },
    isPayerMax() {
      return this.form.paymentSystem === 'PAYER_MAX';
    },
    isStripe() {
      return this.form.paymentSystem === 'STRIPE';
    },
    isPersonal() {
      return this.form.tipsType === 'PERSONAL';
    },
    isDirectPaymentPage() {
      return this.form.paymentPage === 'DIRECT';
    },
    showMultipleTipsSwitcher() {
      return this.isAdminOrSales
        && this.isPersonal
        && !this.isDirectPaymentPage
        && !this.isTipPayoutEmployer;
    },
    showTipPayoutType() {
      return this.isUAE && this.isCommon && ['CHECKOUT_UK_DASHBOARD', 'CHECKOUT_UAE'].includes(this.form.paymentSystem);
    },
    showCaseType() {
      return (this.isUk || this.isUAE) && this.isCommon && (this.isRyft || this.isPayerMax || this.isStripe);
    },
    showPaymentSystem() {
      return this.isUk
        || this.isGermany
        || this.isUAE;
    },
    isGermany() {
      return this.form.country === 'de';
    },
    isUAE() {
      return this.form.country === 'ae';
    },
    isUk() {
      return this.form.country === 'gb';
    },
    showVenue() {
      return ((!this.isGermany && !this.isUAE && !this.showGroups)
        || (this.form.tipsType && !this.venueDisabled))
        && !this.isTipPayoutEmployer;
    },
    showTimeSheet() {
      return this.isCommon
        && !this.isTipPayoutEmployer;
    },
    showTipout() {
      return (!this.isGermany && !this.isUAE && !this.showGroups) || (this.form.tipsType && !this.primaryStaffDisabled);
    },
    showPaymentPage() {
      return this.form.tipsType === 'PERSONAL'
        && !this.isTipPayoutEmployer;
    },
    showTaxesSelect() {
      return !this.showCaseType && !this.isGermany && !this.isUAE && this.form.taxReportingType !== 'SELF_REPORTING';
    },
    showTaxReportingType() {
      return !this.showCaseType && this.isUk;
    },
    showGroups() {
      return this.form.paymentPage === 'GROUPS';
    },
    isSpaOrSalon() {
      return this.form.type === 'SPA'
        || this.form.type === 'BEAUTY_SALON';
    },
    isTaxesDisabled() {
      return this.form.taxReportingType === 'SELF_REPORTING'
        || !this.editable;
    },
    isSupportAdminEditable() {
      return this.editable || this.isSupportAdmin;
    },
    isTaxesRequired() {
      return this.form.taxReportingType === 'EMPLOYER';
    },
    taxesValidationRules() {
      return this.isTaxesRequired ? 'required' : '';
    },
    showMasterLocationSelect() {
      return this.form.type === 'HOTEL';
    },
    isMasterLocation() {
      return this.form.isMasterLocation;
    },
  },

  watch: {
    'form.country': {
      immediate: true,
      async handler(newValue, oldValue) {
        const isOnlyOneType = !this.form.type && this.types.length === 1;
        const noSelectedTypeInCountry = this.form.type && !this.types.some((type) => type.value === this.form.type);

        if (isOnlyOneType || noSelectedTypeInCountry) {
          this.form.type = this.types[0].value;
        }

        if (newValue) {
          try {
            await this.getStaffTypes();
          } catch (e) {
            this.$showServerError(e);
          }
        }

        if (oldValue === 'gb') {
          this.form.taxReportingType = null;
          this.form.taxes = null;
        }

        if (!this.form.status) {
          this.resetPaymentSystem();
        }
      },
    },
    'form.type': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.form.tipsType = null;
          this.form.workplaceFee = null;
          this.form.primaryStaff = null;
          this.form.primaryTipout = 0;
          this.form.paymentPage = null;
          this.form.timesheetType = null;
          this.form.isMasterLocation = false;
          this.form.groups = [];
          this.form.sublocations = [];
          this.primaryStaffAuto();
        }
      },
    },
    'form.tipsType': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.form.tipPayoutType = null;
          this.form.workplaceFee = null;
          this.form.primaryStaff = null;
          this.form.primaryTipout = 0;
          this.form.paymentPage = null;
          this.form.timesheetType = null;
          this.form.groups = [];
          this.form.sublocations = [];
          this.primaryStaffAuto();

          if (this.isUAE && !this.isPersonal) {
            this.form.tipPayoutType = 'INDIVIDUAL';
          }

          this.resetPaymentSystem();
        }
      },
    },
    'form.paymentPage': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.form.groups = [];
        }
      },
    },
    'form.tipPayoutType': {
      handler(value) {
        if (value === 'INDIVIDUAL') {
          this.form.paymentSystem = 'CHECKOUT_UAE';
        } else if (value === 'EMPLOYER') {
          this.form.paymentSystem = 'STRIPE';
        }
      },
    },
    'form.paymentSystem': {
      handler(value) {
        if (value !== 'RYFT') {
          this.form.caseType = null;
        }
      },
    },
    'form.taxes': {
      handler() {
        this.form.caseType = null;
      },
    },
    showPaymentPage(value) {
      if (!value) {
        this.form.paymentPage = null;
      }
    },
    showCaseType(value) {
      if (value) {
        this.form.taxes = null;
        this.form.taxReportingType = null;
      } else {
        this.form.caseType = null;
      }
    },
    showVenue(value) {
      if (!value) {
        this.form.workplaceFee = null;
      }
    },
    venueDisabled() {
      this.form.workplaceFee = null;
    },
    showTipout(value) {
      if (!value) {
        this.form.primaryStaff = null;
        this.form.primaryTipout = 0;
      }

      this.primaryStaffAuto();
    },
    primaryStaffDisabled() {
      this.form.primaryStaff = null;
      this.primaryStaffAuto();
    },
    isCommon(value) {
      if (!value) {
        this.form.timesheetType = null;
      }
    },
  },

  methods: {
    ...mapActions('handbook', [
      'getStaffTypes',
    ]),
    primaryStaffAuto() {
      if (!this.showTipout || this.primaryStaffDisabled) {
        return;
      }

      const values = this.staffTypes.map(({ value }) => value);

      if (values.includes(this.form.primaryStaff)) {
        return;
      }

      if (values.length === 1) {
        [this.form.primaryStaff] = values;
      } else {
        this.form.primaryStaff = null;
      }
    },
    resetPaymentSystem() {
      const needReset = this.paymentSystems.find((system) => system.value === this.form.paymentSystem)?.disabled;

      if (needReset) {
        if (this.isUk || this.isGermany) {
          this.form.paymentSystem = 'CHECKOUT_UK_DASHBOARD';
        } else if (this.isUAE) {
          this.form.paymentSystem = 'CHECKOUT_UAE';
        } else {
          this.form.paymentSystem = null;
        }
      }
    },
  },
};
</script>
